import { Injectable, signal, computed } from "@angular/core"; // Import signal and computed
import { S25EventUtil } from "../utils/s25.event.util";
import { EventService } from "../../../services/event.service";
import { EventMicroService } from "../../../services/event.micro.service";
import { S25Util } from "../../../util/s25-util";
import { EventMircotI } from "../EventMicroI";
import { CacheRepository } from "../../../decorators/cache.decorator";
import { S25Event } from "../EventMicroI";
import { S25Profile } from "../ProfileI";

@Injectable()
export class S25EventOccurrencesService {
    // Define a signal to hold the event state
    private _S25Event = signal<S25Event | null>(null);

    // Expose the signal value (reactive state)
    public get S25Event() {
        return this._S25Event();
    }

    public getOccurrences = computed(() => {
        const event = this.S25Event;
        return event ? S25EventUtil.getAllOccurrences(event) : [];
    });

    public getProfiles = computed(() => {
        const event = this.S25Event;
        return event ? event.profile : [];
    });

    // Add a new profile to the current event state
    addProfile(newProfile: S25Profile) {
        const event = this.S25Event;
        if (event) {
            const updatedEvent = S25EventUtil.addProfile(event, newProfile);
            this._S25Event.set(updatedEvent); // Ensure the signal is updated after modification
            return updatedEvent;
        }
        console.warn("Attempted to add profile to a non-existent event.");
        return null;
    }

    constructor() {}

    // Fetch the event and set it in the signal
    async getEvent(eventId: number, force?: boolean, newUpdateData?: EventMircotI) {
        let taskService = EventService.getEventIncludeCached;
        if (force) {
            CacheRepository.invalidateByService("EventMicroService", "getEventDetailById");
            taskService = EventService.getEventInclude;
        }

        try {
            const data: EventMircotI =
                newUpdateData ||
                (await EventMicroService.getEventDetailById(S25Util.array.forceArray(eventId), "T", "all"));
            data.content.approval = await taskService(eventId, ["workflow"]);
            const event = await S25EventUtil.setFromWS(data.content);
            this._S25Event.set(event);
            return this.S25Event;
        } catch (error) {
            console.error("Error fetching event:", error);
            throw error;
        }
    }

    /// Occurrences Expand All/ Collapse All
    private _expandAll = signal<boolean>(false);

    setExpandAll(showDetail: boolean) {
        this._expandAll.set(showDetail);
    }

    getExpandAll = computed(() => this._expandAll());
}
